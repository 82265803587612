<template>
  <div class="company-selector">
    <template v-if="loaded">
      <company-multi-select
        v-if="companyCount > 5"
        :companies="companies"
        @company-selected="companySelected"
      />
      <company-select-list
        v-else
        :companies="companies"
        @company-selected="companySelected"
      />
    </template>
    <ct-centered-spinner v-else>
      Loading companies...
    </ct-centered-spinner>
  </div>
</template>
<script>

export default {
  name: 'CompanySelector',
  components: {
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner'),
    CompanyMultiSelect: () => import('@/components/shared/CompanyMultiSelect'),
    CompanySelectList:  () => import('@/components/shared/CompanySelectList'),
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: true,
    }
  },
  computed: {
    companyCount() {
      return this.companies?.length || 0
    },
  },
  methods: {
    async companySelected(selectedCompany) {
      this.$emit('company-selected', selectedCompany)
    },
  },
}
</script>
<style lang="scss" scoped>
.company-selector {
  width: 100%;
  max-width: 25em;
}
</style>
