var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-selector" },
    [
      _vm.loaded
        ? [
            _vm.companyCount > 5
              ? _c("company-multi-select", {
                  attrs: { companies: _vm.companies },
                  on: { "company-selected": _vm.companySelected },
                })
              : _c("company-select-list", {
                  attrs: { companies: _vm.companies },
                  on: { "company-selected": _vm.companySelected },
                }),
          ]
        : _c("ct-centered-spinner", [_vm._v("\n    Loading companies...\n  ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }